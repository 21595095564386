import { waitAtLeast } from "./wait";

function request(
  url: string,
  options: any = {},
  wait: number = 0
): Promise<Response> {
  const request = fetch(url, options);
  return wait > 0 ? waitAtLeast<Response>(wait)(request) : request;
}

export function post(
  url: string,
  data: any,
  wait: number = 0
): Promise<Response> {
  return request(
    url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    },
    wait
  );
}

export function get(url: string, wait: number = 0) {
  return request(url, { method: "GET" }, wait);
}

export function del(url: string, wait: number = 0) {
  return request(url, { method: "DELETE" }, wait);
}
