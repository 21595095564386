export function wait(millis: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, millis);
  });
}

export function waitAtLeast<T>(millis: number): (f: Promise<T>) => Promise<T> {
  return async (f: Promise<T>) => {
    const waiter = wait(millis);
    try {
      const [result] = await Promise.all([f, waiter]);
      return result;
    } catch (e) {
      await waiter;
      throw e;
    }
  };
}
